import React from 'react'
import { cloneDeep } from 'lodash'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Button from 'components/Forms/Button/Button'
import SEO from 'components/SEO/SEO'

import PageTopbar from 'layouts/Dashboard/components/PageTopbar/PageTopbar'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { getSpecialTagsData } from 'utils/tag'
import { asyncSetState } from 'utils/react'

import TemplatesTable from './components/TemplatesTable/TemplatesTable'
import CreateTemplateModal from './components/CreateTemplateModal/CreateTemplateModal'
import EditTemplateModal from './components/EditTemplateModal/EditTemplateModal'

import API from 'api'

import { TABLE_PARAMS, MODALS } from './constants'

class InteractyTemplates extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,

            templates: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },

            tagsData: [],

            modal: {
                isOpen: false,
                name: '',
                payload: null,
            },
        }
    }

    async componentDidMount() {
        const [tagsDataResponse] = await Promise.all([getSpecialTagsData(), this.getTemplates()])

        await asyncSetState(this, {
            isReady: true,
            tagsData: tagsDataResponse,
        })
    }

    getTemplates = async (isClear = true) => {
        try {
            const {
                templates: { requestParams },
            } = this.state
            this.setState(prevState => ({
                templates: {
                    ...prevState.templates,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.TEMPLATES.GET_TEMPLATES(requestParams)

            await asyncSetState(this, prevState => ({
                templates: {
                    ...prevState.templates,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.templates.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                templates: {
                    ...prevState.templates,
                    isLoading: false,
                },
            }))
        }
    }

    openModal = async (name, payload = null) => {
        await asyncSetState(this, prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }

    closeModal = async () => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: false,
            },
        }))
    }

    updateTemplate = async data => {
        const { id } = data
        const _data = cloneDeep(data)
        delete _data.nameTranslation
        delete _data.descriptionTranslation
        _data.isTranslationRequested = false
        await API.TEMPLATES.UPDATE_TEMPLATE(id, _data)
        await this.getTemplates()
    }

    createTemplate = async data => {
        await API.TEMPLATES.CREATE_TEMPLATE(data)
        await this.getTemplates()
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            templates: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await asyncSetState(this, prevState => ({
            templates: {
                ...prevState.templates,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getTemplates(isClear)
    }

    render() {
        const { isReady, templates, modal, tagsData } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return (
            <>
                <SEO title="Template gallery / Interacty templates" />

                {isReady ? (
                    <>
                        <PageTopbar>
                            <Button
                                content="+ Create template"
                                variant="primary"
                                onClick={() => this.openModal(MODALS.CREATE_TEMPLATE)}
                            />
                        </PageTopbar>

                        <TemplatesTable
                            list={templates.list}
                            payload={{
                                tagsData,
                            }}
                            methods={{
                                updateTemplate: this.updateTemplate,
                                openModal: this.openModal,
                                changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                            }}
                            pagination={{
                                currentPage: templates.lastResponse.number,
                                totalPages: templates.lastResponse.totalPages,
                                perPageElements: templates.lastResponse.numberOfElements,
                                totalElements: templates.lastResponse.totalElements,
                            }}
                            requestParams={templates.requestParams}
                            onExpand={() =>
                                this.changeRequestParamsAndRefresh(
                                    [
                                        {
                                            field: 'page',
                                            value: templates.lastResponse.number + 1,
                                        },
                                    ],
                                    false,
                                )
                            }
                            onChangePage={page =>
                                this.changeRequestParamsAndRefresh([
                                    {
                                        field: 'page',
                                        value: page,
                                    },
                                ])
                            }
                            isLoading={templates.isLoading}
                        />

                        {isOpenedModal(MODALS.EDIT_TEMPLATE) && (
                            <EditTemplateModal
                                template={modal.payload}
                                onSubmit={this.updateTemplate}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODALS.CREATE_TEMPLATE) && (
                            <CreateTemplateModal onSubmit={this.createTemplate} onClose={this.closeModal} />
                        )}
                        {isOpenedModal(MODALS.CONFIRMATION) && (
                            <ConfirmationDialog
                                onClose={() => this.closeModal()}
                                onAction={() => modal.payload.onAction()}
                                {...modal.payload.data}
                            />
                        )}
                    </>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default InteractyTemplates
